import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {BaseAPIUrl} from "./constants";
import {prepareHeaders} from "./prepareHeaders";

import {
  GeneralErrorResponse,
  GeneralSuccessResponse,
  LoginOrSignUpRequest,
  RequestResetFields,
  ResetPasswordFields,
  StatusErrorResponse
} from "../lib/johncornish/auth/v1/types";
import {
  KeepAliveAllowed,
  KeepAliveDenied,
  LoginResponse,
  LogoutResponse,
  SignUpResponse
} from "../lib/object-control/user";

export const createAuthApi =
  (baseUrl: string = BaseAPIUrl) =>
    createApi({
      reducerPath: 'authApi',
      baseQuery: fetchBaseQuery({
        baseUrl,
        prepareHeaders
      }),
      endpoints: (builder) => ({
        login: builder.mutation<LoginResponse, LoginOrSignUpRequest>({
          query: userCredentials => ({
            url: 'login/',
            method: 'POST',
            body: userCredentials,
          })
        }),
        signUp: builder.mutation<SignUpResponse | StatusErrorResponse, LoginOrSignUpRequest>({
          query: newUserEmailAndPassword => ({
            url: 'signup/',
            method: 'POST',
            body: newUserEmailAndPassword,
          })
        }),
        resetPassword: builder.mutation<GeneralSuccessResponse | GeneralErrorResponse, ResetPasswordFields>({
          query: tokenPasswordAndConfirmPassword => ({
            url: 'password/reset/',
            method: 'POST',
            body: tokenPasswordAndConfirmPassword,
          })
        }),
        sendResetRequest: builder.mutation<GeneralSuccessResponse | GeneralErrorResponse, RequestResetFields>({
          query: requestResetFields => ({
            url: 'password/request_reset',
            method: 'POST',
            body: requestResetFields,
          })
        }),
        logout: builder.mutation<LogoutResponse, void>({
          query: () => ({
            url: 'logout/',
            method: 'DELETE',
          })
        }),
        checkPulse: builder.query<KeepAliveDenied | KeepAliveAllowed, void>({
          query: () => ({
            url: 'keep_alives/',
            method: 'GET'
          })
        })
      })
    });

export const authApi = createAuthApi();

export const {
  useLoginMutation,
  useSignUpMutation,

  useResetPasswordMutation,
  useSendResetRequestMutation,
  useLogoutMutation,
  useCheckPulseQuery
} = authApi;
