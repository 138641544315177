import {DefaultPageLayout} from "../lib/layouts/DefaultPageLayout";
import {useEffect} from "react";
import {APIUserListPage} from "../lib/johncornish/components/v1/list/APIUserListPage";
import {Link} from "react-router-dom";
import {Transaction} from "../lib/transactions/Transaction";
import {useApi} from "../store";

export function CustomerDashboard() {
  const {tryulaApi} = useApi();

  const {
    refetch,
    isUninitialized,
    data: transactions,
    error,
    isLoading,
  } = tryulaApi.useGetAllTransactionsQuery();

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (!isUninitialized) {
        refetch();
      }
    }, 5_000);

    return () => {
      clearInterval(intervalId);
    }
  }, []);

  return <>
    <APIUserListPage
      items={transactions}
      error={error}
      isLoading={isLoading}
      resourcePlural="transactions"
      resourceSingular="transaction"
      pageLayoutComponent={DefaultPageLayout}
      itemComponent={Transaction}
      emptyResultComponent={CustomerNoTransactions}/>
  </>
}

// ViewableTransaction is close but doesn't quite cut it
const CustomerNoTransactions = () =>
  <div data-testid="no-customer-transactions-found" className="mt-8 text-center">
    <h1 className="font-semibold mb-8">No transactions found.</h1>
    <Link
      to="/"
      className="bg-[#a9d046] text-white py-4 px-4 rounded-[100px] font-bold uppercase"
      data-testid="submit-sign-up">
      <svg className="inline-block me-2" xmlns="http://www.w3.org/2000/svg" width="17" height="18" viewBox="0 0 17 18"
           fill="none">
        <path
          d="M10.8191 13.27C10.2291 13.55 9.69906 13.84 9.13906 14.05C8.06906 14.45 6.91906 14.55 5.79906 14.35C4.21906 14.09 2.79906 13.28 1.77906 12.05C0.879057 11.01 0.339058 9.70005 0.239058 8.32005C0.00905771 6.12005 0.909057 3.95005 2.62906 2.55005C3.65906 1.66005 4.94906 1.13005 6.30906 1.02005C9.99906 0.670049 13.2691 3.38005 13.6191 7.07005C13.7491 8.45005 13.4491 9.84005 12.7591 11.04C12.6591 11.16 12.6691 11.35 12.7891 11.45C12.7991 11.46 12.8091 11.46 12.8191 11.47C14.0091 12.65 15.1991 13.84 16.3791 15.02C16.9391 15.51 16.9991 16.36 16.4991 16.93C16.0091 17.49 15.1591 17.55 14.5891 17.05C14.5491 17.01 14.5091 16.97 14.4691 16.93C13.2991 15.77 12.1391 14.6 10.9791 13.43C10.9091 13.39 10.8491 13.31 10.8191 13.27ZM6.91906 3.18005C4.40906 3.18005 2.37906 5.21005 2.37906 7.72005C2.37906 10.23 4.40906 12.26 6.91906 12.26C9.42906 12.26 11.4591 10.23 11.4591 7.72005V7.71005C11.4591 5.21005 9.42906 3.18005 6.92906 3.18005C6.91906 3.18005 6.91906 3.18005 6.91906 3.18005Z"
          fill="white"></path>
      </svg>
      <span className="inline-block" data-testid="no-transactions-search-agents">
        SEARCH AGENTS
      </span>
    </Link>
  </div>;