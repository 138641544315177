import {useDispatch} from "react-redux";
import {useNavigate, useSearchParams} from "react-router-dom";
import {setCredentials} from "../features/auth/userDataSlice";
import {RedError} from "../lib/styled-components/RedError";
import {LoginResponse} from "../lib/object-control/user";
import {ReactNode, useEffect, useState} from "react";
import {
  LoginOrSignUpParams,
  RequestResetFields,
  ResetPasswordFields,
  SignUpStateFields
} from "../lib/johncornish/auth/v1/types";
import {PageTitle} from "../lib/johncornish/components/v1/screen/PageTitle";
import {GreenText} from "../lib/styled-components/GreenText";
import {StateValidators} from "../lib/johncornish/components/v1/form/types";
import {createFormSubmitHandler} from "../lib/johncornish/components/v1/form/createFormSubmitHandler";
import {Input} from "../lib/johncornish/components/v1/form/Input";
import {textInputStateUpdater} from "../lib/johncornish/components/v1/form/utils";
import {BasicLoadingElement} from "../lib/johncornish/components/v1/form/BasicLoadingElement";
import {StandardInputClassName} from "../lib/styles";
import {signUpHref} from "../lib/utils";
import {useScrollingPrivacyPolicyBox} from "../lib/auth/UseScrollingPrivacyPolicyBox";
import {useApi} from "../store";

export const LoginPage = () => {
  const {tryulaApi, authApi} = useApi();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const returnUrl = searchParams.get('return_url');
  const explainLogin = searchParams.get('explain');
  const prePopulatedEmail = searchParams.get('email');
  const resetPasswordToken = searchParams.get('reset_password_token');

  const [anyLoginError, setAnyLoginError] =
    useState('');

  const [loginFields, setLoginFields] =
    useState<LoginOrSignUpParams>({
      email: prePopulatedEmail || '',
      password: '',
    });

  const [resetFields, setResetFields] =
    useState<ResetPasswordFields>(({
      token: resetPasswordToken,
      password: '',
      confirm_password: ''
    }));

  const [
    privacyPolicyConsentFields,
    setPrivacyPolicyConsentFields
  ] = useState({privacy_policy_consent: false});

  const scrollingPrivacyPolicyBox = useScrollingPrivacyPolicyBox(
    setPrivacyPolicyConsentFields
  );

  const {
    data: privacyCheckResult,
    error: privacyCheckError,
    isLoading: isPrivacyCheckLoading
  } = tryulaApi.useGetPrivacyPolicyRequiredQuery(resetPasswordToken || 'invalid');


  const [
    privacyConsentRequired,
    setPrivacyConsentRequired
  ] = useState(false); // comes from API

  useEffect(() => {
    if (!resetPasswordToken) {
      return
    }

    if (!!privacyCheckError) {
      return
    }

    if (isPrivacyCheckLoading) {
      return
    }

    if (!privacyCheckResult) {
      return
    }

    const {
      privacy_policy_consent_required,
      user_email
    } = privacyCheckResult;
    setPrivacyConsentRequired(privacy_policy_consent_required);
    setLoginFields((prev) =>
      ({...prev, email: user_email}));
  }, [isPrivacyCheckLoading, privacyCheckError, privacyCheckResult, resetPasswordToken]);

  const conditionalValidatePrivacy: StateValidators =
    privacyConsentRequired
      ? [['',
        () => privacyPolicyConsentFields.privacy_policy_consent,
        'You must agree to the privacy policy']]
      : [];

  const [isRequestingReset, setIsRequestingReset] =
    useState(false);
  const [isRequestResetComplete, setIsRequestResetComplete] = useState(false)
  const [sendResetFields, setSendResetFields] =
    useState<RequestResetFields>({
      email: ''
    })

  const [
    login,
    {isLoading: isLoginLoading}
  ] = authApi.useLoginMutation();
  const [
    resetPassword,
    {isLoading: isResetPasswordLoading}
  ] = authApi.useResetPasswordMutation();
  const [
    sendResetRequest,
    {isLoading: isSendResetRequestLoading}
  ] = authApi.useSendResetRequestMutation();

  const onLoginSuccess = ({user, token}: LoginResponse) => {
    dispatch(setCredentials({user, token}));

    if (user?.user_type === 'TryulaAdmin' || user?.user_type === 'Admin') {
      navigate('/dashboard');
      return;
    }

    if (user?.user_type === 'Agent' && user?.agent?.published) {
      navigate('/transactions');
      return;
    }

    if (user?.user_type === 'Agent' && !user?.agent?.published) {
      navigate(`/agent/${user.agent?.id}`);
      return;
    }

    if (!returnUrl) {
      navigate('/');
      return;
    }

    if (returnUrl === '/logout') {
      navigate('/');
      return;
    }

    if (returnUrl === '/login') {
      navigate('/');
      return;
    }

    if (returnUrl === '/sign-up') {
      navigate('/');
      return;
    }

    // Don't navigate outside the app; we don't use that anymore
    if (!/^\/.*$/.test(returnUrl)) {
      navigate('/');
      return;
    }

    navigate(returnUrl);
  };

  function submitHandlerAndFieldsFor(
    isRequestingReset: boolean,
    resetPasswordToken: string | null
  ): [(e: any) => void, ReactNode] {
    if (isRequestingReset) {
      return [
        createFormSubmitHandler({
          setMutateError: setAnyLoginError,
          mutator: sendResetRequest,
          state: sendResetFields,
          onSuccess: () => {
            setIsRequestResetComplete(true);
          }
        }),
        isRequestResetComplete ? <p data-testid="request-reset-complete">
          Check your email for further instructions.
          You may now close this tab.
        </p> : <>
          <div>
            <label htmlFor="emailAddress">Email Address</label>

            <Input id="emailAddress"
                   autoFocus={true}
                   className={StandardInputClassName}
                   forStateField="email"
                   resourceName="user"
                   state={sendResetFields}
                   setState={setSendResetFields}/>
          </div>

          <input data-testid="submit-request-reset"
                 className="bg-[#a9d046] text-white w-44 p-2 py-2 text-center rounded-[100px] font-bold uppercase"
                 type="submit"
                 value="Send Reset Link"/>

          <button onClick={() => setIsRequestingReset(false)} data-testid="cancel-request-reset">Cancel</button>

          {!!anyLoginError && <RedError>{anyLoginError}</RedError>}
        </>
      ];
    }

    if (!!resetPasswordToken) {
      return [
        createFormSubmitHandler({
          setMutateError: setAnyLoginError,
          mutator: resetPassword,
          state: resetFields,
          onSuccess: () => {
            dispatch(setCredentials({user: null, token: null}));
            navigate('/login?explain=true');
          },
          validate: [
            ['confirm_password',
              (state: SignUpStateFields) => !!state.password && state.password === state.confirm_password,
              'Passwords must match'],
            ...conditionalValidatePrivacy
          ]
        }),
        <>
          <div>
            <label htmlFor="emailAddress">Email Address</label>
            <input id="emailAddress"
                   autoFocus={true}
                   className={StandardInputClassName}
                   value={loginFields.email}
                   name="email"
                   data-testid="email"
                   type="text"/>
          </div>

          <div>
            <label htmlFor="password">Password</label>

            <Input id="password"
                   className={StandardInputClassName}
                   type="password"
                   forStateField="password"
                   resourceName="user"
                   state={resetFields}
                   setState={setResetFields}/>
          </div>

          <div>
            <label htmlFor="confirmPassword">Confirm Password</label>

            <Input id="confirmPassword"
                   className={StandardInputClassName}
                   type="password"
                   forStateField="confirm_password"
                   resourceName="user"
                   state={resetFields}
                   setState={setResetFields}/>
          </div>

          {privacyConsentRequired &&
            <div className="sm:w-2/3">
              {scrollingPrivacyPolicyBox}
            </div>}

          {!!anyLoginError && <RedError>{anyLoginError}</RedError>}

          <input name="submit-reset-password"
                 data-testid="submit-reset-password"
                 className="bg-[#a9d046] text-white w-54 p-2 py-2 text-center rounded-[100px] font-bold uppercase"
                 type="submit"
                 value="Confirm Password"/>
        </>
      ];
    }

    const handleLoginChange = textInputStateUpdater(loginFields, setLoginFields);
    return [
      createFormSubmitHandler({
        setMutateError: setAnyLoginError,
        mutator: login,
        wrapState: state => ({user: state}),
        state: loginFields,
        onSuccess: onLoginSuccess,
        beforeMutate: () => dispatch(setCredentials({user: null, token: null}))
      }),
      <>
        <div>
          <label htmlFor="emailAddress">Email Address</label>
          <input id="emailAddress"
                 autoFocus={true}
                 className={StandardInputClassName}
                 defaultValue={loginFields.email}
                 onChange={handleLoginChange}
                 name="email"
                 data-testid="email"
                 type="text"/>
        </div>

        <div>
          <label htmlFor="password">Password</label>

          <input id="password"
                 className={StandardInputClassName}
                 onChange={handleLoginChange}
                 name="password"
                 data-testid="password"
                 type="password"/>
        </div>

        <input data-testid="submit-login"
               className="bg-[#a9d046] text-white w-44 p-2 py-2 text-center rounded-[100px] font-bold uppercase"
               type="submit"
               value="Log In"/>

        <button
          className="text-[#27AAE1] block"
          onClick={() => setIsRequestingReset(true)}
          data-testid="request-reset-password">
          Forgot Password
        </button>

        {!!anyLoginError && <RedError>{anyLoginError}</RedError>}
      </>
    ];
  }

  const [handleOnSubmit, fields] =
    submitHandlerAndFieldsFor(isRequestingReset, resetPasswordToken);

  return <div>
    <PageTitle title="Login"/>
    <div className="flex flex-col items-center">
      {!isRequestingReset && !resetPasswordToken
        && <div className="grid grid-cols-2 items-center gap-x-4 mb-6">
          <button
            data-testid="login-tab"
            disabled={true}
            className="col-span-1 bg-[#27AAE1] border border-[#27AAE1] rounded py-2 px-6 text-center text-white hover:cursor-pointer">
            Login
          </button>
          <a
            data-testid="sign-up-tab"
            href={signUpHref(returnUrl)}
            className="col-span-1 bg-transparent border border-[#27AAE1] rounded py-2 px-6 text-center hover:cursor-pointer">
            Sign Up
          </a>
        </div>}

      <form className="flex flex-col items-center gap-y-6" onSubmit={handleOnSubmit}>
        {!!explainLogin && <GreenText>
          Success! Now please log in with your credentials.
        </GreenText>}
        {fields}
        {(isLoginLoading || isResetPasswordLoading || isSendResetRequestLoading)
          && <BasicLoadingElement/>}
      </form>
    </div>
  </div>;
};