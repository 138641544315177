import {combineReducers, configureStore, Store} from "@reduxjs/toolkit";
import {createTryulaApi, tryulaApi} from "./services/tryulaApi";
import {Api, setupListeners} from "@reduxjs/toolkit/query";
import userDataReducer from './features/auth/userDataSlice';
import storage from "redux-persist/lib/storage";
import {FLUSH, PAUSE, PERSIST, persistReducer, persistStore, PURGE, REGISTER, REHYDRATE} from "redux-persist";
import {authApi, createAuthApi} from "./services/authApi";
import {createContext, useContext} from "react";

const reducers = {
  [tryulaApi.reducerPath]: tryulaApi.reducer,
  [authApi.reducerPath]: authApi.reducer,
  userData: userDataReducer,
};

export const combinedReducer = combineReducers(reducers);

const persistConfig = {
  key: 'root',
  whitelist: ['userData'],
  version: 1,
  storage,
}

const rootReducer = persistReducer(persistConfig, combinedReducer);

export interface Apis {
  tryulaApi: Api<any, any, any, any, any>;
  authApi: Api<any, any, any, any, any>;
}

interface StoreAndApis extends Apis {
  store: Store;
}

export const defaultApis: Apis = {
  tryulaApi,
  authApi,
}

export const ApiContext = createContext(defaultApis);

export const useApi = (): Apis => {
  const context = useContext(ApiContext);

  if (!context) throw new Error('Apis must be used within ApiContext.Provider');

  return context;
}

export function createStoreWithApiUrl(apiUrl?: string): StoreAndApis {
  // TODO delete after I move to new mock server pattern
  if (!apiUrl) {
    return {
      store: configureStore({
        reducer: rootReducer,
        middleware: (getDefaultMiddleware) =>
          getDefaultMiddleware({
            serializableCheck: {
              ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            }
          })
            .concat(
              authApi.middleware,
              tryulaApi.middleware
            )
      }),
      tryulaApi,
      authApi,
    };
  }

  const tryulaApiWithApiUrl = createTryulaApi(apiUrl);
  const authApiWithApiUrl = createAuthApi(apiUrl);

  const reducersWithApiUrl = {
    [tryulaApiWithApiUrl.reducerPath]: tryulaApiWithApiUrl.reducer,
    [authApiWithApiUrl.reducerPath]: authApiWithApiUrl.reducer,
    userData: userDataReducer,
  };

  return {
    store: configureStore({
      reducer: persistReducer(persistConfig, combineReducers(reducersWithApiUrl)),
      middleware:
        (getDefaultMiddleware) =>
          getDefaultMiddleware({
            serializableCheck: {
              ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            }
          })
            .concat(
              authApi.middleware,
              tryulaApiWithApiUrl.middleware
            )
    }),
    tryulaApi: tryulaApiWithApiUrl,
    authApi: authApiWithApiUrl
  };
}

export const {store} = createStoreWithApiUrl()

export const persistor = persistStore(store, null, () => {
  console.log('Rehydration complete');
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
