import {ContentHome} from "../config/siteSettings";
import {CheckboxState} from "./johncornish/components/v1/filter/types";
import {AgentFilter} from "./object-control/agent";
import {fill, zipObject} from "lodash";

export const toTestId = (...parts: string[]) => parts.join("-").toLowerCase();

export const isPrice = (price?: string | null) => !!price?.match(/^\$?[0-9,.]+$/);

export function loginHref(returnUrl?: string | null, explain: boolean = false, email: string | null = null): string {
  const explainParam = explain ? "&explain=true" : '';
  const emailParam = !!email ? `&email=${encodeURIComponent(email)}` : '';
  return `/login?return_url=${
    returnUrl
    || window.location.pathname + encodeURIComponent(window.location.search)
  }${explainParam}${emailParam}`;
}

export function seededAgentSearchParams(
  seed: string | null,
  filter?: AgentFilter
): string {
  if (!seed?.match(/^[0-9]+$/)) {
    return '';
  }

  const seedStr = `?seed=${seed}`;

  function commaSeparateSelected(paramKey: string, checkboxState: CheckboxState) {
    const commaSeparatedOrEmpty = Object
      .entries(checkboxState)
      .filter(([, v]: [string, boolean]) => v)
      .map(([k]: [string, boolean]) => encodeURIComponent(k))
      .join(',');

    return !!commaSeparatedOrEmpty ? `${paramKey}=${commaSeparatedOrEmpty}` : '';
  }

  function queryParams(filter?: AgentFilter) {
    if (!filter) {
      return '';
    }

    const parts = [
      !!filter.name ? `name=${encodeURIComponent(filter.name)}` : '',
      commaSeparateSelected('service_areas', filter.service_areas),
      commaSeparateSelected('languages', filter.languages)
    ]
      .filter(Boolean)
      .join('&');

    return !!parts ? `&${parts}` : '';
  }

  return `${seedStr}${queryParams(filter)}`;
}

export function signUpHref(returnUrl?: string | null, returnQuery?: string, showPopUp: boolean = false): string {
  const remainderOfQuery = !!returnQuery ? `?${returnQuery}` : '';
  const signUpPopUp = showPopUp ? 'show_popup=true&' : '';
  return `/sign-up?${signUpPopUp}return_url=${returnUrl || window.location.pathname}${remainderOfQuery}`;
}

export function logoutHrefWithOverrideUrl(): string {
  return `/logout?override_url=${ContentHome}`;
}

export function checkboxSelectionFromQueryString(commaSeparatedFilterSelection: string | null): CheckboxState {
  const selectionKeys = commaSeparatedFilterSelection?.split(',').filter(Boolean) || [];
  return zipObject(selectionKeys, fill(Array(selectionKeys.length), true))
}

export const decodedChosenValuesFromQueryString =
  (commaSeparatedFilterSelection: string | null): string[] =>
    commaSeparatedFilterSelection?.split(',').filter(Boolean).map(decodeURIComponent) || [];
